import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
 
  /* Reset
  ------------------------------------------------------------------------*/
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  main, article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  
  body {
    line-height: 1;
  }
  
  ol, ul {
    list-style: none;
  }
  
  blockquote, q {
    quotes: none;
  }
  
  blockquote:before,
  blockquote:after,
  q:before, q:after {
    content: "";
    content: none;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  // IE placeholder input clear ['X'] hide
  ::-ms-clear {
      display: none;
  }
  
  li {
    list-style: none;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  /* Abrr */
  abbr[title],
  abbr[data-original-title] {
    border: 0;
  }
  
  /* Global
  ------------------------------------------------------------------------*/

  /* montserrat-300 - latin-ext */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('/fonts/montserrat/montserrat-v18-latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/montserrat/montserrat-v18-latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* montserrat-regular - latin-ext */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/fonts/montserrat/montserrat-v18-latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/montserrat/montserrat-v18-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* montserrat-500 - latin-ext */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('/fonts/montserrat/montserrat-v18-latin-ext-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/montserrat/montserrat-v18-latin-ext-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* montserrat-600 - latin-ext */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('/fonts/montserrat/montserrat-v18-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/montserrat/montserrat-v18-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  ///* montserrat-700 - latin-ext */
  //@font-face {
  //  font-family: 'Montserrat';
  //  font-style: normal;
  //  font-weight: 700;
  //  font-display: swap;
  //  src: local(''),
  //  url('/fonts/montserrat/montserrat-v18-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  //  url('/fonts/montserrat/montserrat-v18-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  //}
  ///* montserrat-800 - latin-ext */
  //@font-face {
  //  font-family: 'Montserrat';
  //  font-style: normal;
  //  font-weight: 800;
  //  font-display: swap;
  //  src: local(''),
  //  url('/fonts/montserrat/montserrat-v18-latin-ext-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  //  url('/fonts/montserrat/montserrat-v18-latin-ext-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  //}
  ///* montserrat-900 - latin-ext */
  //@font-face {
  //  font-family: 'Montserrat';
  //  font-style: normal;
  //  font-weight: 900;
  //  font-display: swap;
  //  src: local(''),
  //  url('/fonts/montserrat/montserrat-v18-latin-ext-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  //  url('/fonts/montserrat/montserrat-v18-latin-ext-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  //}
 
  *,
  *:before, 
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }  
  
  body, html {
    width: 100%;
    height: 100%;
  }

  body {
    background-color: #fff;
    color: ${props => props.theme.colors.primary};
    text-align: left;
    font-size: 14px;
    line-height: 1.15;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 16px;
      line-height: 1.33;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 18px;
    }
  }
  
  p {
	  margin-bottom: 1em;
  }

  p:not([class]) {
    color: ${props => props.theme.colors.dark};
  }

  h1, .h1, h2, .h2, h3, .h3 {
    font-size: 34px;
    font-weight: 500;
    line-height: 1;
  }
  
  h1, h2, h3 {
    color: ${props => props.theme.colors.secondary};
  }

  h1, .h1 {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 52px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 74px;
    }
  }

  h2, .h2 {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 44px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 64px;
    }
  }

  h3, .h3 {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 32px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 44px;
    }
  }
  
  .lead {
	  font-size: 18px;
	  font-weight: 500;
    line-height: 1.3;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 22px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 24px;
    }
  }
  
  .small {
    color: #000;
	  font-size: 12px;

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 14px;
    }
  }
  
  a, button {
    cursor: pointer;
    text-decoration: none;
    transition: all .2s;
    border: 0;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  }
  
  a {
	  color: inherit;
  }

  ul:not([class]) {
    margin: 8px 0 16px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-bottom: 24px;
    }

    li {
      position: relative;
      padding: 0 0 8px 8px;
      color: ${props => props.theme.colors.dark};

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.dark};

        @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
          top: 10px;
        }
      }
    }
  }
  
  .lead {
    ul:not([class]) {
	    margin-bottom: 8px;
	    
      li {
	      color: ${props => props.theme.colors.primary};

        &:before {
          background-color: ${props => props.theme.colors.primary};

          @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
            width: 4px;
            height: 4px;
            top: 15px;
          }
        }
      }
    }
  }
  
  .condensed {
    color: ${props => props.theme.colors.dark};

    ul:not([class]) {
      li {
	      padding-bottom: 2px;
      }
    }
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .overflow-hidden {
	  overflow: hidden;
  }
  
  .c-primary {
    color: ${props => props.theme.colors.primary}
  }

  .text-right {
	  text-align: right;
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    .show-for-large {
      display: none !important;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    .hide-for-large {
			display: none !important;
    }

    .two-columns {
			column-count: 2;
	    column-gap: 20px;
    }
  }
  
  //swiper
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    position: relative;
    transition-property: transform;
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
	    height: 100%;
	  }
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    .mobile-disabled {
      .swiper-wrapper {
        flex-flow: row wrap;
        pointer-events: none !important;
      }
    }
  }
`

export default GlobalStyle
