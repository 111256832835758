import React from 'react'
import { Link } from 'gatsby'
import * as Styled from './Footer.styles'
import * as LayoutStyled from '../Layout/Layout.styles'
import { global } from '../../config/global'
import { Column, Row } from '../Grid'
import { SocialMediaList } from './Footer.styles'

const Footer = () => (
  <Styled.Footer>
    <Row alignJustify alignBottom>
      <Column xs={12} largeOrder={2} largeShrink>
        <Styled.Slogan className="h2">
          Z nami <br />
          dotrzesz <br />
          <span>do celu</span>.
        </Styled.Slogan>
      </Column>
      <Column xs={12} largeShrink>
        <Styled.Title className="lead">
          Nordic Logistics Polska Sp. z o.o.
        </Styled.Title>
        <p className="lead">
          <a href={`tel:${global.phone}`}>{global.phone}</a>
          <br />
          <a href={`mailto:${global.email}`}>{global.email}</a>
        </p>
      </Column>
    </Row>
    <Row>
      <Column xs={12} lg={6} largeOrder={2}>
        <Styled.SocialMediaList>
          <li>
            <LayoutStyled.Fb
              href="https://www.facebook.com/NordicTeamPL/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <span className="sr-only">Facebook Nordic Team</span>
            </LayoutStyled.Fb>
          </li>
          <li>
            <LayoutStyled.Ig
              href="https://www.instagram.com/nordic_logistics_team/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <span className="sr-only">Instagram Nordic Team</span>
            </LayoutStyled.Ig>
          </li>
          <li>
            <LayoutStyled.In
              href="https://pl.linkedin.com/company/nordic-logistics-polska"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <span className="sr-only">Linkedin NordicLogistics Polska</span>
            </LayoutStyled.In>
          </li>
        </Styled.SocialMediaList>
      </Column>
      <Column xs={12} lg={6}>
        <Link to="/polityka-prywatnosci">Polityka Prywatności</Link>
      </Column>
    </Row>
    <Row column>
      <Styled.Copyright>© Copyright 2021 Nordic Logistics</Styled.Copyright>
    </Row>
  </Styled.Footer>
)

export default Footer
